import {
  ALL_BATCH_FAIL,
  ALL_BATCH_REQUEST,
  ALL_BATCH_SUCCESS,
  BATCH_DETAIL_FAIL,
  BATCH_DETAIL_REQUEST,
  BATCH_DETAIL_SUCCESS,
  CLEAR_BATCH_DELETE_STATE,
  CLEAR_BATCH_ERRORS,
  CLEAR_BATCH_STATE,
  CREATE_BATCH_FAIL,
  CREATE_BATCH_REQUEST,
  CREATE_BATCH_SUCCESS,
  DELETE_BATCH_FAIL,
  DELETE_BATCH_REQUEST,
  DELETE_BATCH_SUCCESS,
  UPDATE_BATCH_FAIL,
  UPDATE_BATCH_REQUEST,
  UPDATE_BATCH_SUCCESS,
} from "../constant/batchConstant";

export const batchCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_BATCH_REQUEST:
      return {
        loading: true,
        batchData: {},
      };
    case CREATE_BATCH_SUCCESS:
      return {
        loading: false,
        batchData: action.payload,
      };
    case CREATE_BATCH_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_BATCH_ERRORS:
      return {
        ...state,
        error: null,
      };
    case CLEAR_BATCH_STATE:
      return {
        loading: false,
        batchData: {},
      };

    default:
      return state;
  }
};

// UPDATE COURSE REDUCER
export const batchUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_BATCH_REQUEST:
      return {
        loading: true,
        batchData: {},
      };
    case UPDATE_BATCH_SUCCESS:
      return {
        loading: false,
        batchData: action.payload,
      };
    case UPDATE_BATCH_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_BATCH_ERRORS:
      return {
        ...state,
        error: null,
      };
    case CLEAR_BATCH_STATE:
      return {
        loading: false,
        batchData: {},
      };

    default:
      return state;
  }
};

// GET ALL COURSES REDUCER
export const getAllBatchReducer = (state = {}, action) => {
  switch (action.type) {
    case ALL_BATCH_REQUEST:
      return {
        loading: true,
        batchList: {},
      };
    case ALL_BATCH_SUCCESS:
      return {
        loading: false,
        batchList: action.payload,
      };
    case ALL_BATCH_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_BATCH_ERRORS:
      return {
        batchList: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// GET COURSE DETAILS REDUCER
export const getBatchDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case BATCH_DETAIL_REQUEST:
      return {
        loading: true,
        batchDetails: {},
      };
    case BATCH_DETAIL_SUCCESS:
      return {
        loading: false,
        batchDetails: action.payload,
      };
    case BATCH_DETAIL_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_BATCH_ERRORS:
      return {
        batchDetails: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// DELETE COURSE DETAILS REDUCER
export const deleteBatchReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_BATCH_REQUEST:
      return {
        loading: true,
        batch: {},
      };
    case DELETE_BATCH_SUCCESS:
      return {
        loading: false,
        batch: action.payload,
      };
    case DELETE_BATCH_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_BATCH_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_BATCH_DELETE_STATE:
      return {
        batch: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
