import React, { Fragment } from 'react'
import styled from 'styled-components';

const Button = styled.button`
  background-color: #02298A;
  color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  border: 1px solid #02298A;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  line-height:0;
  height: 40px;
  border-radius:40px;
  width: ${(props) => props.width};
  transition: 0.4s;

  &:hover {
    background-color: #F0F5FB;
    color: #02298A;
  }
`;
const ManualButton = ({
  className,
  onClick,
  width,
  disabled,
  title,
  icon,
  type
}) => {
  return (
    <Fragment>
      <Button
        className={`${className}`}
        onClick={onClick}
        width={width}
        type={type}
        disabled={disabled}
      >

        {title} {icon}
      </Button>
    </Fragment>
  )
}

export default ManualButton