import React from 'react'
import styled from 'styled-components'
const Wrapper = styled.div`
    // width:400px;
    border-radius:12px;
    display:flex;
    margin:auto;
    justify-content:center;
    color:#f2f2f2;
    box-sizing:border-box;
    padding:30px;
    background:linear-gradient(180deg, #0575E6 0%, #02298A 84.79%, #021B79 100%);
    backdrop-filter: blur(21px);

    @media(max-width:767px){
      padding:10px;
    }
    >.data_txt{
      text-align:center;
      margin:30px 0px;
      h2{
        color:#f2f2f2;
        letter-spacing:1px;
        font-size: 20px;
        font-weight: 500;
        line-height:1.5;
        display: flex;
        align-items: center; 
        
    @media(max-width:767px){
      padding:10px;
    }
      }
      .icon{
        color:#f2f2f2;
        
        >svg{
          font-size:32px;
          margin-bottom:30px;
        }
      }
    }
  
    
`
const NoDataCard = ({ txt, icon }) => {
  return (
    <Wrapper>
      <div className='data_txt'>
        {icon && <span className="icon">{icon}</span>}
        <h2>
          {txt}
        </h2>
      </div>
    </Wrapper>
  )
}

export default NoDataCard