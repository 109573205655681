import React, { useState } from "react";
import "../../styles/ProfileLayout.scss";
import { NavLink } from "react-router-dom";
import { RiCloseFill, RiMenuUnfoldLine } from "react-icons/ri";
import { userImg } from "../../assest/images";
import { headerLinks } from "./headerLinks";
import { useAuth } from "../../context/userContext";

const ProfileLayout = ({ children }) => {
  const [showSideNav, setShowSideNav] = useState(false);
  const [auth] = useAuth();


  return (
    <section className="profile_layout_main">
      <button
        className="open_close_sidebar"
        onClick={() => setShowSideNav(!showSideNav)}
      >
        {showSideNav ? <RiCloseFill /> : <RiMenuUnfoldLine />}
      </button>
      <div
        className={`profile_sidebar ${
          showSideNav ? "showProfile_sidebar" : ""
        }`}
      >
       
        <div className="sidebar_content">
          <div className="user_profileCard">
            <img
              src={
                auth?.user?.profile?.url
                  ? auth?.user?.profile?.url
                  : userImg
              }
              alt="alpha regiment user"
            />
            <h1>
              {auth?.user?.fullName}
              {/* {auth?.user?.middle_name}{" "}
              {auth?.user?.last_name} */}
            </h1>
          </div>

          {headerLinks?.map((item) => {
            return (
              <div className="profile_sidebarLink" key={item.id}>
                <NavLink to={item?.link}>{item?.name}</NavLink>
              </div>
            );
          })}
        </div>
      </div>
      <div className="profile_content">{children}</div>
    </section>
  );
};

export default ProfileLayout;
