export const CREATE_BATCH_REQUEST = "CREATE_BATCH_REQUEST";
export const CREATE_BATCH_SUCCESS = "CREATE_BATCH_SUCCESS";
export const CREATE_BATCH_FAIL = "CREATE_BATCH_FAIL";

export const UPDATE_BATCH_REQUEST = "UPDATE_BATCH_REQUEST";
export const UPDATE_BATCH_SUCCESS = "UPDATE_BATCH_SUCCESS";
export const UPDATE_BATCH_FAIL = "UPDATE_BATCH_FAIL";

export const ALL_BATCH_REQUEST = "ALL_BATCH_REQUEST";
export const ALL_BATCH_SUCCESS = "ALL_BATCH_SUCCESS";
export const ALL_BATCH_FAIL = "ALL_BATCH_FAIL";

export const BATCH_DETAIL_REQUEST = "BATCH_DETAIL_REQUEST";
export const BATCH_DETAIL_SUCCESS = "BATCH_DETAIL_SUCCESS";
export const BATCH_DETAIL_FAIL = "BATCH_DETAIL_FAIL";

export const DELETE_BATCH_REQUEST = "DELETE_BATCH_REQUEST";
export const DELETE_BATCH_SUCCESS = "DELETE_BATCH_SUCCESS";
export const DELETE_BATCH_FAIL = "DELETE_BATCH_FAIL";
export const CLEAR_BATCH_DELETE_STATE = "CLEAR_BATCH_DELETE_STATE";

export const CLEAR_BATCH_ERRORS = "CLEAR_BATCH_ERRORS";
export const CLEAR_BATCH_STATE = "CLEAR_BATCH_STATE";


