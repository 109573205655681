import React from "react";
import styled, { keyframes } from "styled-components";

const diamondLoader = keyframes`
0% {
    top: 0;
    color: white;
  }
  50% {
    top: 30px;
    color: rgba(255, 255, 255, 0.2);
  }
  100% {
    top: 0;
    color: white;
  }
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  color: #f2f2f2;
  background-color: #02298a;
  > div {
    text-align: center;
    back > p {
      font-size: 34px;
      font-family: nunito;
      font-weight: bold;
      margin-bottom: 12px;
      @media (max-width: 767px) {
        font-size: 24px;
      }
    }
  }
`;
const LoaderSpan = styled.span`
  width: 16px;
  height: 16px;
  box-shadow: 0 30px, 0 -30px;
  border-radius: 4px;
  background: currentColor;
  display: block;
  margin: -50px auto 0;
  position: relative;
  color: #FFF;
  margin-left:auto;
  margin-right:auto;
  transform: translateY(30px);
  box-sizing: border-box;
  animation: ${diamondLoader} 2s ease infinite;
  &:after {
    content: '';  
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    box-shadow: 0 30px, 0 -30px;
    border-radius: 4px;
    background: currentColor;
    color: #FFF;
    position: absolute;
    left: -30px;
    top: 0;
    animation:  ${diamondLoader} 2s 0.2s ease infinite;
  }
  &:before {
    content: '';  
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    box-shadow: 0 30px, 0 -30px;
    border-radius: 4px;
    background: currentColor;
    color: #FFF;
    position: absolute;
    left: 30px;
    top: 0;
    animation:  ${diamondLoader} 2s 0.2s ease infinite;
    animation-delay: 0.4s;
  }
`;

const Loader = () => {
  return (
    <Wrapper>
      <LoaderSpan />
    </Wrapper>
  );
};

export default Loader;
